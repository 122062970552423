import styled from "styled-components"

const Banner = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${props => props.bannerImage});
  background-position: ${props => props.backgroundPosition || "center"};
  background-size: cover;
  height: ${props => props.height || 600}px;
  width: 100%;
`

export default Banner
