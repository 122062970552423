import { Link } from "gatsby"
import moment from "moment"
import { rgba } from "polished"
import React from "react"
import styled from "styled-components"

const PostContainer = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`

const PostContent = styled.div`
  padding: 10px 40px;

  h3 {
    font-size: 1.4rem;
  }
`

const PostImg = styled.img`
  width: 100%;
`

const PostWrapper = styled(Link)`
  border: 1px solid ${props => props.theme.alto};
  color: ${props => props.theme.nero};
  display: block;
  flex: 1 0 25%;
  text-decoration: none;
  transition: box-shadow 0.3s, color 0.3s;

  :first-child {
    margin-left: 0;
  }

  :hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    color: ${props => rgba(props.theme.tundora, 0.6)};
  }

  :last-child {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    margin: 1rem 0;
  }
`

// Requires being defined after Post
const AuthorText = styled.p`
  color: ${props => rgba(props.theme.tundora, 0.5)};
  font-size: 1.2rem;
  transition: color 0.3s;

  ${PostWrapper}:hover & {
    color: ${props => rgba(props.theme.tundora, 0.3)};
  }
`

const StyledContent = styled.div`
  a {
    color: inherit;
  }

  p {
    font-size: 1.2rem;
  }
`

const PostPreview = ({ nodes }) => (
  <PostContainer>
    {nodes.map(node => (
      <PostWrapper to={`/articles/${node.slug}`}>
        <PostImg
          alt={node.featuredImage.altText}
          src={node.featuredImage.mediaItemUrl}
        />
        <PostContent>
          <h3>{node.title}</h3>
          <AuthorText>
            By {node.author.name} on{" "}
            <time datetime={node.date}>
              {moment(node.date).format("MMMM Do YYYY")}
            </time>
          </AuthorText>
          <StyledContent dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        </PostContent>
      </PostWrapper>
    ))}
  </PostContainer>
)

export default PostPreview
